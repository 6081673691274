'use client'

import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline'
import Container from '@tebuto/layout/Container'
import { TRANSLATIONS } from './translations'
import { Heading } from './LandingComponents'

export default function FAQ(): JSX.Element {
    return (
        <Container id="faq" className="py-32">
            <div className="mx-auto max-w-6xl">
                <Heading className="mb-24">{TRANSLATIONS.landing.FAQ.title}</Heading>
                <div className="divide-y divide-gray-900/10 my-16">
                    <FAQContent />
                </div>
            </div>
        </Container>
    )
}

export function FAQContent(): JSX.Element {
    return (
        <dl className="grid space-y-6 divide-y divide-gray-900/10">
            {TRANSLATIONS.landing.FAQ.items.map(faq => (
                <Disclosure as="div" key={faq.question} className="pt-6">
                    {({ open }) => (
                        <>
                            <dt>
                                <DisclosureButton className="flex w-full items-start justify-between text-left">
                                    <span className="text-landing font-semibold leading-7 text-gray-700">{faq.question}</span>
                                    <span className="ml-6 flex h-7 items-center">
                                        {open ? <MinusIcon className="h-6 w-6" aria-hidden="true" /> : <PlusIcon className="h-6 w-6" aria-hidden="true" />}
                                    </span>
                                </DisclosureButton>
                            </dt>
                            <DisclosurePanel as="dd" className="mt-2 pr-12">
                                <p className="text-lg leading-8 my-6 font-sans">{faq.answer}</p>
                            </DisclosurePanel>
                        </>
                    )}
                </Disclosure>
            ))}
        </dl>
    )
}
