export function Heading({ children, className, ...rest }: { children: React.ReactNode; className?: string; rest?: React.HTMLAttributes<HTMLHeadingElement> }) {
    return (
        <h2 className={`hero:text-7xl lg:text-5xl text-3xl leading-[1.1] font-semibold text-gray-900 tracking-tighter font-sans ${className ? className : ''}`} {...rest}>
            {children}
        </h2>
    )
}

export function LandingSection({ children, className, id, ...rest }: { children: React.ReactNode; className?: string; id?: string; rest?: React.HTMLAttributes<HTMLDivElement> }) {
    return (
        <section id={id} className={`max-w-6xl mx-auto max-xl:px-6 py-32 ${className ? className : ''}`} {...rest}>
            {children}
        </section>
    )
}

export interface IFeaturePoint {
    title: string
    description: string
    Icon: any
}

export function FeaturePoint({ feature: { Icon, title, description } }: { feature: IFeaturePoint }) {
    return (
        <div className="flex gap-6">
            <Icon className="lg:h-28 lg:w-28 md:h-20 md:w-20 h-12 w-12 text-primary-600 !stroke-[0.25] max-md:!stroke-1 flex-shrink-0 flex-grow-0 max-sm:hidden" />
            <div>
                <div className="flex gap-2 items-center max-sm:mb-4">
                    <Icon className="lg:h-28 lg:w-28 md:h-20 md:w-20 h-12 w-12 text-primary-600 !stroke-1 flex-shrink-0 flex-grow-0 sm:hidden" />
                    {/* biome-ignore lint/security/noDangerouslySetInnerHtml: Trust me bro */}
                    <h3 className="2xl:text-3xl text-2xl font-medium text-gray-800" dangerouslySetInnerHTML={{ __html: title }} />
                </div>
                <p className="text-landing mt-1">{description}</p>
            </div>
        </div>
    )
}
